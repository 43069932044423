// Generated by Framer (461de17)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ArkjLhEmc", "FYft4hNo3"];

const serializationHash = "framer-xx6JK"

const variantClassNames = {ArkjLhEmc: "framer-v-16rgjse", FYft4hNo3: "framer-v-1o2k8vb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.3, ease: [0, 1.1, 0.75, 0.53], type: "tween"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "ArkjLhEmc", "Variant 2": "FYft4hNo3"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ArkjLhEmc"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ArkjLhEmc", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapi967ci = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("FYft4hNo3")
})

const onTapx3r8z8 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("ArkjLhEmc")
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "FYft4hNo3") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fit", positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/9KH28ikxC2nS7fx6HcpREVxI.png"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-16rgjse", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"ArkjLhEmc"} onTap={onTapi967ci} ref={ref ?? ref1} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, ...style}} variants={{FYft4hNo3: {backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 3, borderBottomRightRadius: 3, borderTopLeftRadius: 3, borderTopRightRadius: 3}}} {...addPropertyOverrides({FYft4hNo3: {"data-framer-name": "Variant 2", background: undefined, onTap: onTapx3r8z8}}, baseVariant, gestureVariant)}>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "10px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 101, 18))"}}>7016066950</motion.p></React.Fragment>} className={"framer-nj8hqs"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"XA80ssbB0"} style={{"--extracted-r6o4lv": "rgb(255, 101, 18)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/>)}</Image></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xx6JK.framer-wfmjjt, .framer-xx6JK .framer-wfmjjt { display: block; }", ".framer-xx6JK.framer-16rgjse { cursor: pointer; height: 39px; position: relative; width: 44px; }", ".framer-xx6JK .framer-nj8hqs { flex: none; height: auto; left: 50%; position: absolute; top: 50%; white-space: pre; width: auto; z-index: 1; }", ".framer-xx6JK.framer-v-1o2k8vb.framer-16rgjse { display: grid; gap: 23px; grid-auto-rows: minmax(0, 1fr); grid-template-columns: repeat(2, minmax(50px, 1fr)); grid-template-rows: repeat(2, minmax(0, 1fr)); height: 40px; justify-content: center; padding: 0px; width: 78px; }", ".framer-xx6JK.framer-v-1o2k8vb .framer-nj8hqs { order: 0; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-xx6JK.framer-v-1o2k8vb.framer-16rgjse { gap: 0px; } .framer-xx6JK.framer-v-1o2k8vb.framer-16rgjse > *, .framer-xx6JK.framer-v-1o2k8vb.framer-16rgjse > :first-child, .framer-xx6JK.framer-v-1o2k8vb.framer-16rgjse > :last-child { margin: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 39
 * @framerIntrinsicWidth 44
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"FYft4hNo3":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerb3WMqB8Qi: React.ComponentType<Props> = withCSS(Component, css, "framer-xx6JK") as typeof Component;
export default Framerb3WMqB8Qi;

Framerb3WMqB8Qi.displayName = "Call";

Framerb3WMqB8Qi.defaultProps = {height: 39, width: 44};

addPropertyControls(Framerb3WMqB8Qi, {variant: {options: ["ArkjLhEmc", "FYft4hNo3"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerb3WMqB8Qi, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})